import React, { useEffect } from 'react';

import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';

import './styles.css';

const LojaShopping = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Header />

      <section id="loja-shopping">
        <div className="p-d-flex p-jc-center header-shopping">
          <div className="p-col-12 p-md-10 p-lg-8 p-d-flex p-jc-center p-ai-end">
            <h1 data-aos="zoom-out-up">LOJA SHOPPING</h1>
          </div>
        </div>

        <div className="p-grid p-ml-0 p-mr-0 p-mb-6">
          <div className="p-col aside-bar"></div>
          <div className="p-col-6 p-md-4 p-lg-2 p-pt-0 p-pb-0">
            <h3>QUEM BUSCA?</h3>
          </div>
          <div className="p-col aside-bar"></div>
        </div>

        <div className="p-d-flex p-jc-center p-p-4">
          <div className="p-col-12 p-md-10 p-lg-6">
            <div className="box-radius">
              <ul>
                <li>A franqueadora possui um especialista para as negociações de pontos comerciais dentro deste perfil, desde que os custos de ocupação não inviabilizem o negócio;</li>
                <li>Normalmente, esses modelos possuem atendimentos expressos. Porém, sempre que possível, implantar área destinada ao cliente para consumo do produto;</li>
                <li>Área mínima de 35 m².</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="p-grid p-ml-0 p-mr-0 p-mt-6 p-mb-6">
          <div className="p-col aside-bar"></div>
          <div className="p-col-8 p-md-4 p-lg-2 p-pt-0 p-pb-0">
            <h3>INVESTIMENTO</h3>
          </div>
          <div className="p-col aside-bar"></div>
        </div>

        <div className="p-d-flex p-jc-center p-p-4">
          <div className="p-col-12 p-md-10 p-lg-4">
            <div className="box-radius">
              <ul>
                <li>Investimento inicial: a partir de R$ 400 mil*</li>
                <li>Taxa de Franquia: R$ 60 mil</li>
                <li>Faturamento médio mensal: R$ 90 mil a R$ 95 mil</li>
                <li>Taxa de Royalties: 5%</li>
                <li>Taxa de publicidade: 2%</li>
                <li>Lucro médio mensal: entre 17% e 22%</li>
                <li>Prazo de retorno: de 24 a 36 meses</li>
              </ul>
              <em>*Pode variar de acordo com o tamanho e infraestrutura do local, exigências do shopping, impostos, frete etc.</em>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default LojaShopping;
