import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Slider from 'react-slick';


// import Slide8 from '../../assets/img/slide8.jpg';
/* import Slide10 from '../../assets/img/slide10.jpg'; */
// import Slide10 from '../../assets/img/Slide10-Correto.jpg';
// import Slide11 from '../../assets/img/Slide11.jpg';
// import Slide14 from '../../assets/img/slide14.jpg';
// import Slide15 from '../../assets/img/slide15.png';
//import Slide16 from '../../assets/img/slide16.png';
//import Slide17 from '../../assets/img/slide17.png';
//import BannerOreoDesktop from '../../assets/img/banner-desktop-site-oreo.jpg';
//import BannerNovosMilkShakesDesktop from '../../assets/img/2024/02/banner-site-milk-shakes-01-02-2024.jpg';
//import BannerEDDesktop from '../../assets/img/2024/04/banner-site-edicao-limitada.jpg';
import BannerNutellaDesktop from '../../assets/img/2024/07/banner-site-nutella-desktop.jpg';

import './styles.css';

export default class Carousel extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      arrows: false,
      draggable: false,
    };

    return (
      <Slider {...settings}>
    
        {/*<div>
          <a href={blackfridaypdf} target='_blank'><img src={SlideBlackFriday} alt="Black Chiquinho é 10" /></a>
    </div>*/}

        <img src={BannerNutellaDesktop} alt="Chiquinho S2 Nutella - Seu dia mais feliz" />
  
      </Slider>
    );
  }
}
