import React, { useEffect } from 'react';

import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';

import './styles.css';

const ModelosExternos = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Header />

      <section id="modelos-externos">
        <div className="p-d-flex p-jc-center header-modelos-externos">
          <div className="p-col-12 p-md-10 p-lg-8 p-d-flex p-jc-center p-ai-end">
            <h1 data-aos="zoom-out-up">MODELOS EXTERNOS</h1>
          </div>
        </div>

        <div className="p-grid p-ml-0 p-mr-0 p-mb-6">
          <div className="p-col aside-bar"></div>
          <div className="p-col-6 p-md-4 p-lg-2 p-pt-0 p-pb-0">
            <h3>QUEM BUSCA?</h3>
          </div>
          <div className="p-col aside-bar"></div>
        </div>

        <div className="p-d-flex p-jc-center p-p-4">
          <div className="p-col-12 p-md-10 p-lg-6">
            <div className="box-radius">
            <h4>Perfil</h4>

              <ul>
                <li>Os modelos externos possuem um design mais prático e otimizado, levando em consideração os pontos estratégicos para a implantação desta modalidade;</li>
                <li>Esses modelos de negócio foram desenvolvidos para atender as necessidades de expansão da Chiquinho Sorvetes em áreas como postos de combustíveis, Food Parks, estacionamentos, outlets e áreas externas com grande fluxo de pessoas.</li>
              </ul>

              <h4>Módulo Externo</h4>
              <ul>
                <li>Deve ser instalado em um imóvel de 12,5m²;</li> 
                <li>O Modelo também pode ser adaptado para atender no sistema de drive-thru.</li>
              </ul>

              <h4>Quiosque Externo</h4>
              <ul>
                <li>Deve ser instalado em um imóvel de 9m².</li>
              </ul>


            </div>
          </div>
        </div>

        <div className="p-grid p-ml-0 p-mr-0 p-mt-6 p-mb-6">
          <div className="p-col aside-bar"></div>
          <div className="p-col-8 p-md-4 p-lg-2 p-pt-0 p-pb-0">
            <h3>INVESTIMENTO</h3>
          </div>
          <div className="p-col aside-bar"></div>
        </div>

        <div className="p-d-flex p-jc-center p-p-4">
          <div className="p-col-12 p-md-10 p-lg-4">
            <div className="box-radius">
              <ul>
                <li>Investimento inicial: de R$ 390 mil a R$ 420 mil</li>
                <li>Taxa de Franquia: R$ 45 mil</li>
                <li>Faturamento médio mensal: R$ 60 mil</li>
                <li>Taxa de Royalties: 5%</li>
                <li>Taxa de publicidade: 2%</li>
                <li>Lucro médio mensal: entre 20% e 25%</li>
                <li>Prazo de retorno: de 24 a 36 meses</li>
              </ul>
              <em>*Pode variar de acordo com o tamanho e infraestrutura do local, exigências do shopping, impostos, frete etc.</em>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ModelosExternos;
