import React, { useEffect } from 'react';

import Header from '../../../../components/Header';
import Footer from '../../../../components/Footer';

import './styles.css';

const LojaTradicional = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Header />

      <section id="loja-tradicional">
        <div className="p-d-flex p-jc-center header-tradicional">
          <div className="p-col-12 p-md-10 p-lg-8 p-d-flex p-jc-center p-ai-end">
            <h1 data-aos="zoom-out-up">LOJA TRADICIONAL</h1>
          </div>
        </div>

        <div className="p-grid p-ml-0 p-mr-0 p-mb-6">
          <div className="p-col aside-bar"></div>
          <div className="p-col-6 p-md-4 p-lg-2 p-pt-0 p-pb-0">
            <h3>QUEM BUSCA?</h3>
          </div>
          <div className="p-col aside-bar"></div>
        </div>

        <div className="p-d-flex p-jc-center p-p-4">
          <div className="p-col-12 p-md-10 p-lg-6">
            <div className="box-radius">
              <ul>
                <li>Cidades com mais de 50 mil habitantes;</li>
                <li>Instaladas em centros comerciais, com grande fluxo de pessoas, próximas a bancos, lojas do varejo, bares e restaurantes;</li>
                <li>Em cidades que não possuem Shopping Center, a Chiquinho se tornará uma das principais áreas de lazer da população;</li>
                <li>Deve ser instalada em um imóvel com mais de 50 m², 100 m² ou acima de 100 m² de área útil e com espaço para que o cliente possa sentar-se com a família ou amigos e ter a experiência num ambiente agradável, climatizado e com som ambiente.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="p-grid p-ml-0 p-mr-0 p-mt-6 p-mb-6">
          <div className="p-col aside-bar"></div>
          <div className="p-col-8 p-md-4 p-lg-2 p-pt-0 p-pb-0">
            <h3>INVESTIMENTO</h3>
          </div>
          <div className="p-col aside-bar"></div>
        </div>

        <div className="p-d-flex p-jc-center p-p-4">
          <div className="p-col-12 p-md-10 p-lg-4">
            <div className="box-radius">
              <ul>
                <li>Investimento inicial: a partir de R$ 450 mil*</li>
                <li>Taxa de Franquia: R$ 60 mil</li>
                <li>Faturamento médio mensal: R$ 80 mil a R$ 85 mil</li>
                <li>Taxa de Royalties: 5%</li>
                <li>Taxa de publicidade: 2%</li>
                <li>Lucro médio mensal: entre 17% e 22%</li>
                <li>Prazo de retorno: de 24 a 36 meses</li>
              </ul>
              <em>*Pode variar de acordo com o tamanho e infraestrutura do local, exigências do shopping, impostos, frete etc.</em>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default LojaTradicional;
